import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";

const Thankyou = () => {
  const [conversionTracked, setConversionTracked] = useState(false);
  const location = useLocation();
  const { orderId, cartTotal, customerId, currency } = location.state || {};

  const hasTrackedConversion = useRef(false);

  useEffect(() => {
    if (hasTrackedConversion.current || conversionTracked) {
      return;
    }
    if (
      !conversionTracked &&
      cartTotal > 0 &&
      orderId &&
      customerId &&
      currency
    ) {
      const value = cartTotal || "";
      const orderIdString = orderId.toString() || "";
      const customerIdString = customerId.toString() || "";

      if (typeof window !== "undefined" && window.fbq) {
        window.fbq("track", "Purchase", {
          value: value,
          currency: currency,
          content_ids: [orderIdString],
          content_type: "product",
          debug: true,
        });
      }
      if (typeof window !== "undefined" && window.gtag) {
        window.gtag("event", "conversion", {
          send_to: "AW-1004084329/NXhnCKr96-cZEOm45N4D",
          value: value,
          currency: currency,
          transaction_id: orderIdString,
        });
      }

      if (typeof window !== "undefined" && window.etag) {
        window.etag("event", "conversion", {
          value: cartTotal,
          order_id: orderIdString,
          currency: currency,
          transaction_id: "TRANSACTION_ID",
          customer_email: "CUSTOMER_EMAIL",
          customer_id: customerIdString,
          customer_first_name: "CUSTOMER_FIRST_NAME",
          customer_last_name: "CUSTOMER_LAST_NAME",
          customer_total_spent: cartTotal,
          customer_phone: "CUSTOMER_PHONE",
          customer_accepts_marketing: "CUSTOMER_ACCEPTS_MARKETING",
        });
      }
      setConversionTracked(true);
      hasTrackedConversion.current = true;
    }
  }, [conversionTracked, cartTotal, orderId, customerId, currency]);

  return (
    <div className="container mt-5 mb-5">
      <div className="row justify-content-center mt-5">
        <div className="col-md-6">
          <div className="card mt-5">
            <div className="card-body">
              <h2 className="card-title text-center mb-4">
                Success Notification
              </h2>
              <p className="text-success text-center">
                Thank you for Ordering!
              </p>
              <p className="text-success text-center">Success your Payment</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Thankyou;
